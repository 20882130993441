<script setup lang="ts">
import type { VoixNumberFieldInterface } from '@voix/types'

const props = defineProps<{
  inView: boolean
  fields: {
    opacity: VoixNumberFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Glowing Background', group: 'Background', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesBackgroundsGlow.jpg',
  fields: {
    opacity: {
      type: 'number',
      label: 'Opacity',
      default: 30,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const opacity = computed(() => {
  if (!props.fields.opacity)
    return 0.3
  return props.fields.opacity.value / 100
})
</script>

<template>
  <div class="absolute inset-0 z-0 ">
    <EffectsRandomlyMovingSvg v-if="inView" class="absolute inset-0 z-0" :style="{ opacity }" />
  </div>
</template>
